import React from 'react';
import { useState } from 'react';
import { Form, Dropdown } from 'react-bootstrap';
import { Image } from 'react-bootstrap';
import { Card, Button } from 'react-bootstrap';


const Annonce = ({d, onDelete, visible, onDesactived, onActivated, onModify}) => {

    const [newData, setNewData] = useState()
    const [modifyNow, setModifyNow] = useState(false)

    function toggleModify() {
        setModifyNow(!modifyNow)
    }
    
    return (d.visible === visible) ? (
        <form onSubmit={onModify}>
            <div className="my-5">
                <Card>
                    <Card.Header>{modifyNow ? <input type="text" value={d.titre} name="titre_annonce" /> : d.titre}</Card.Header>
                    <Card.Body>
                        <Card.Title>
                            <div className="row">
                            
                            { modifyNow ? <>
                                <div className="col-md-6">
                                <Form.Select aria-label="Default select example">
                                    <option>Open this select menu</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                </Form.Select>
                                </div>
                            </> : d["Type d'annonce"]} <div className="col-md-6">{ modifyNow ? <input type="number" name='prix_annonce' value={d.prix} /> : d.prix}€ HT</div>
                            </div>
                        </Card.Title>
                        <Card.Text>
                            {d.images.map((image) => <Image src={image} width="200px" />)}
                        </Card.Text>
                        <Card.Text>
                            {d.description}
                        </Card.Text>
                        { (visible === 1 && !modifyNow ) && <Button onClick={() => toggleModify()} variant="success">Modifier l'annonce</Button>}
                        {(visible === 1 && modifyNow) && <Button type="submit" variant="success">Enregister les modifications</Button>} 
                        { visible === 1 && <Button dataannonce={JSON.stringify(d)} idfordesactived={d.id} onClick={onDesactived} variant="warning">Désactiver l'annonce</Button>}
                        { visible === 0 && <Button dataannonce={JSON.stringify(d)} idforactivated={d.id} onClick={onActivated} variant="success">Réactiver l'annonce</Button>}
                        <Button variant="danger" idfordeleted={d.id} onClick={onDelete}>Supprimer l'annonce</Button>
                    </Card.Body>
                </Card>
            </div>
        </form>
        
    ) : <></>;
};


export default Annonce;