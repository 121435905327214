import React from 'react';
import './css/Connect.css'
import { Toaster, toast } from 'sonner'
import { sha256 } from 'js-sha256';
import { useContext } from 'react';
import { ConnectedContext } from '../hooks/useConnectedContext';

const Connect = () => {

    const {connected, toggleConnected} = useContext(ConnectedContext)

    function connexion(e) {
        e.preventDefault();
        const data = new FormData(e.target);

        if(data.get('name') === "" || data.get('value') === "") {
            toast.error("Aucun champ ne peut être vide")
        } else {
            const name    = sha256(data.get('name'))
            const value  = sha256(data.get('value'))

            const url = `https://api.frediere.com?t=phantom&name=${name}&value=${value}`

            fetch(url, {method: 'GET'}).then(res => res.json()).then((data) => {
                if(data.length > 0) {
                    toggleConnected()
                } else {
                    toast.error("Erreur d'authantification")
                }
            }).catch(e => console.error(e))

        }
    }

    return (
        <>
            <Toaster richColors />
            <form onSubmit={connexion} class="login">
                <center>Connexion</center>
                <input type="text" name="name" placeholder="Identifiant" />
                <input type="password" name="value" placeholder="Mot de passe" />
                <button>Connexion</button>
            </form>
        </>
        
    );
};

export default Connect;