import { Tabs, Tab, Container, Card, Form } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import { useContext, useEffect, useRef, useState } from "react";
import Annonce from "./components/Annonce";
import { Toaster, toast } from 'sonner'
import ImageUploading from 'react-images-uploading';
import slugify from 'react-slugify';
import { ConnectedContext } from "./hooks/useConnectedContext";
import Connect from "./components/Connect";
import { CardHeader } from "react-bootstrap";
import { CardBody } from "react-bootstrap";

function App() {

  const [switchReload, setSwitchReload] = useState()
  const [allData, setAllData] = useState([])
  const [commerciaux, setCOmmerciaux] = useState()
  const [validated, setValidated] = useState(false)
  const [images, setImages] = useState([]);
  const [imagesAffaire, setImagesAffaire] = useState([]);
  const maxNumber = 69;
  const [detailsFieldsList, setDetailsFieldsList] = useState([]);
  const [pdf, setPdf] = useState('')
  const [pdfOn, setPdfOn] = useState(false);

  let refDetailName = useRef("")
  let refDetailValue = useRef("")

  const {connected, toggleConnected} = useContext(ConnectedContext);
  
  useEffect(() => {
    fetch('https://api.frediere.com?t=commerciaux', {method: 'GET'}).then(resC => resC.json()).then((dC) => setCOmmerciaux(dC))
    fetch('https://api.frediere.com?t=annonces', {method: 'GET'}).then(res => res.json()).then((d) => setAllData(d))
    fetch('https://api.frediere.com?t=magasin_affaire', {method: 'GET'}).then(resM => resM.json()).then(m => {setImagesAffaire([{data_url: m.value}]); console.log(m.name)})
    fetch('https://api.frediere.com?t=magasin_cat', {method: 'GET'}).then(resC => resC.json()).then(m => {setPdf(m.base64); (m.afficher === "on" ? setPdfOn(true) : setPdfOn(false)) ; console.log(m)})
  }, [switchReload])

  const onChangeImage = (imageList, addUpdateIndex) => {
    // data for submit
    setImages(imageList);
  };

  const onChangeImageAffaireDuMois = (imageList, addUpdateIndex) => {
    setImagesAffaire(imageList);
  };

  function ListenActivate(e) {
    const data = JSON.parse(e.target.attributes.dataannonce.value);
    data.visible = 1;
    fetch('https://api.frediere.com?t=annonces&id=' + e.target.attributes.idforactivated.value, {method: 'PUT',  headers: {
      'Content-type': 'application/json',
    },body: JSON.stringify(data)}).finally(setSwitchReload(!switchReload))
    toast.success("Annonce réactivée avec succès");

    setSwitchReload(!switchReload);
  }

  function ListenDelete(e) {

    const id = e.target.attributes.idfordeleted.value;
  
    fetch('https://api.frediere.com?t=annonces&id=' + id, {method: 'DELETE'})
    toast.success("Annonce supprimée avec succès");
    setSwitchReload(!switchReload);
  
  }

  function ListenDesactived(e) {

    const data = JSON.parse(e.target.attributes.dataannonce.value);

    data.visible = 0;

    fetch('https://api.frediere.com?t=annonces&id=' + e.target.attributes.idfordesactived.value, {method: 'PUT',  headers: {
      'Content-type': 'application/json',
    },body: JSON.stringify(data)})
    toast.success("Annonce désactivée avec succès");
    setSwitchReload(!switchReload);
  
  }

  function ListenModify(e) {

    e.preventDefault()

    let data = new FormData(e.target);

  }

  function newAnnonce(e) {

    e.preventDefault();

    const data = new FormData(e.target)

    const form = e.currentTarget;
    
    if(form.checkValidity() === false) {
      
      e.stopPropagation();
    } else {
      let photos = [];
      images.map((item) => photos.push(item.data_url))

      const newAnnonce = {
        visible: 1,
        titre: data.get('titreAnnonce'),
        type: data.get('typeAnnonce'),
        prix: data.get('prixAnnonce'),
        etat: data.get('etatAnnonce'),
        annee: data.get('anneeAnnonce'),
        images: photos,
        slug: slugify(data.get('titreAnnonce')),
        details: detailsFieldsList,
        description: data.get('descriptionAnnonce'),
        commercial: data.get('commercialAnnonce')
      }
      fetch('https://api.frediere.com?t=annonces', {method: 'POST',  headers: {
      'Content-type': 'application/json',
    },body: JSON.stringify(newAnnonce)})
    toast.success("Annonce postée avec succès");
    }
    setSwitchReload(!switchReload)
    setValidated(true)
    

  }

  function actilization() {
    setTimeout(setSwitchReload(!switchReload), 2000)
    
  }

  async function postAffaireDuMois(e) {
    e.preventDefault();

    if(imagesAffaire.length === 0) {
      toast.error("Vous devez obligatoirement rensseigner une affaire du mois")
    } else {
      await fetch('https://api.frediere.com?t=magasin_affaire', { method: 'PUT', mode:'cors', headers: { 'Content-type': 'application/json' }, body: JSON.stringify({name: "affaire_du_mois", value: imagesAffaire[0].data_url}) }).finally(setSwitchReload(!switchReload))
      toast.success(`L'affaire du mois à bien été mis en ligne`)
      setSwitchReload(!switchReload);
      actilization()
    }

  }

  function postPDF(e) {

    e.preventDefault();
    const data = new FormData(e.target)

    let selectedFile = data.get('file')
    let file = null;
    let fileName = "";
    if (selectedFile) {
      let fileToLoad = selectedFile;
      fileName = fileToLoad.name;
      let fileReader = new FileReader();
      fileReader.onload = function(fileLoadedEvent) {
          file = fileLoadedEvent.target.result;
          fetch('https://api.frediere.com?t=magasin_cat', { method: 'PUT',  headers: { 'Content-type': 'application/json' }, body: JSON.stringify({name: "catalogue", base64: file, afficher: data.get('afficher')}) }).finally(setSwitchReload(!switchReload))
          toast.success(`Le catalogue à été modifier selon les préférences`)
          setSwitchReload(!switchReload);
      };
      fileReader.readAsDataURL(fileToLoad);
  }

  }

  function addCommercial(e) {
    e.preventDefault();
    const data = new FormData(e.target)
    fetch('https://api.frediere.com?t=commerciaux', {method: 'POST',  headers: {
      'Content-type': 'application/json',
    },body: JSON.stringify(
      {
        nom: data.get('nomCommercial'),
        tel: data.get('telCommercial')
      }
    )})
    console.log('reload', switchReload)
    toast.success("Commercial créé avec succès");
    
    }



  if(!connected) {
    return <Connect />
  }

  return (
    <>
      <Toaster richColors expand={false} />
      <Container >
      <div class="jumbotron">
        <h1 class="display-4">frediere.com <button onClick={actilization} className="btn btn-warning">Forcer l'actualisation</button></h1>
        <Tabs
      defaultActiveKey="home"
      id="uncontrolled-tab-example"
      className="mb-3"
    >
      <Tab eventKey="home" title="Annonces en ligne">
        
      {
          allData && allData.map((annonce) => <Annonce d={annonce} visible={1} onDesactived={ListenDesactived} onDelete={ListenDelete} onModify={ListenModify} />)
        }
        
      </Tab>
      <Tab eventKey="profile" title="Annonces désactivées">
      {
          allData && allData.map((annonce) => <Annonce d={annonce} visible={0} onDelete={ListenDelete} onActivated={ListenActivate}/>)
      }
      </Tab>
      <Tab eventKey="contact" title="Ajouter une annonce">
        
      <Form noValidate validated={validated} onSubmit={newAnnonce}>
            <div className="my-5">
                <Card>
                    <Card.Header>Créer une nouvelle annonce</Card.Header>
                    <Card.Body>
                        <Card.Text>
                          <div className="row">
                            <div className="col-md-4">
                            <Form.Label htmlFor="typeAnnonce">Type</Form.Label>
                              <Form.Select name="typeAnnonce" id="typeAnnonce">
                                <option value="vente">Vente</option>
                                <option value="location">Location</option>
                              </Form.Select>
                            </div>
                            
                            <div className="col-md-4">
                            <Form.Label htmlFor="commercialAnnonce">Commercial</Form.Label>
                              <Form.Select name="commercialAnnonce" id="commercialAnnonce">
                                {
                                  commerciaux && commerciaux.map((item) => <option value={item.id}>{item.nom}</option>)
                                }
                              </Form.Select>
                            </div>
                            <div className="col-md-4">
                            <Form.Label htmlFor="etatAnnonce">Etat</Form.Label>
                              <Form.Select name="etatAnnonce" id="etatAnnonce">
                                <option value="neuf">Neuf</option>
                                <option value="Comme neuf">Comme neuf</option>
                                <option value="Très bon état">Très bon état</option>
                                <option value="Bon état">Bon état</option>
                                <option value="Travaux à pévoir">Travaux à pévoir</option>
                              </Form.Select>
                            </div>
                          </div>
                          <div className="row mt-4">
                            <div className="col-md-6">
                              <Form.Label htmlFor="titreAnnonce">Titre de l'annonce</Form.Label>
                              <Form.Control required type="text" name="titreAnnonce" id="titreAnnonce" placeholder="ex: machine agricole"/>
                            </div>
                            <div className="col-md-4">
                              <Form.Label htmlFor="prixAnnonce">Prix HT</Form.Label>
                              <Form.Control required type="number" id="prixAnnonce" name="prixAnnonce" placeholder="ex: 4000"/>
                            </div>
                            <div className="col-md-2">
                              <Form.Label htmlFor="anneeAnnonce">Année</Form.Label>
                              <Form.Control required type="number" min="1800" max={new Date().getFullYear()} id="anneeAnnonce" name="anneeAnnonce" placeholder={ `Ex: ${new Date().getFullYear()}` }/>
                            </div>
                          </div>
                        </Card.Text>
                        <Card.Text>
                            <div className="row">
                              
                              <Form.Label htmlFor="prixAnnonce">Images de l'annonce</Form.Label>
                              <ImageUploading multiple value={images} onChange={onChangeImage} maxNumber={maxNumber} dataURLKey="data_url" >
                                {({
                                  imageList,
                                  onImageUpload,
                                  onImageRemoveAll,
                                  onImageUpdate,
                                  onImageRemove,
                                  isDragging,
                                  dragProps,
                                }) => (
                                    <>
                                      <button
                                      type="button"
                                        style={isDragging ? { color: 'red' } : undefined}
                                        onClick={onImageUpload}
                                        {...dragProps}
                                        className="btn btn-secondary"
                                      >
                                        Cliquer pour ajouter une photo
                                      </button>
                                      {imageList.map((image, index) => (
                                        <div className="col-md-2" >
                                        <div key={index} className="image-item">
                                          <img src={image['data_url']} alt="" width="100" />
                                          <div className="image-item__btn-wrapper">
                                            <button type="button" className="btn btn-warning btn-sm" onClick={() => onImageUpdate(index)}>Update</button>
                                            <button type="button" className="btn btn-danger btn-sm" onClick={() => onImageRemove(index)}>Remove</button>
                                          </div>
                                        </div>
                                        </div>
                                      ))}
                                    
                                  </>)}
                                </ImageUploading>
                              </div>
                              <br />
                              <div className="row">
                                <Form.Label htmlFor="infosAnnonces">Les différentes détails</Form.Label>
                                  <div className="col-md-4">
                                    <input type="text" placeholder="Nom du detail" ref={refDetailName} id="detailName" className="form-control"/>
                                  </div>
                                  <div className="col-md-4">
                                    <input type="text" placeholder="Valeur du detail" ref={refDetailValue} className="form-control"/>
                                  </div>
                                  <div className="col-md-4">
                                    <button type="button" className="btn btn-success" onClick={addFieldDetails}>Ajouter un champ détails</button>
                                  </div>
                              </div>
                              <div className="row">
                                <div className="colo-md-12">
                                  {
                                    detailsFieldsList.map((item, index) => <><b>{item.detail}:</b> { item.value } <button onClick={deleteDetailInList} idDetailDelete={index + 1} className="btn btn-danger btn-small" type="button">Supprimer</button><br /></>)
                                  }
                                </div>
                              </div><br />
                              <div className="row" >
                                <div className="col-md-12" >
                                <Form.Label htmlFor="infosAnnonces"><u>Description</u></Form.Label><br />
                                <textarea required rows='5' className="form-control" placeholder="description" name="descriptionAnnonce" ></textarea>
                                </div>
                              </div>
                        </Card.Text>
                        <button className="btn btn-success">Poster l'annonce</button>
                    </Card.Body>
                </Card>
            </div>
        </Form>
      </Tab>
      <Tab eventKey="magasins" title="Nos magasins">
        <Form onSubmit={postAffaireDuMois}>
        <div className="my-5">
        <Card>
          <Card.Header>Les affaires du mois</Card.Header>
          <Card.Body>
            <Card.Text>
            <ImageUploading multiple value={imagesAffaire} onChange={onChangeImageAffaireDuMois} maxNumber={1} dataURLKey="data_url" >
                                {({
                                  imageList,
                                  onImageUpload,
                                  onImageRemoveAll,
                                  onImageUpdate,
                                  onImageRemove,
                                  isDragging,
                                  dragProps,
                                }) => (
                                    <>
                                    {
                                      imageList.length === 0 && <button type="button" style={isDragging ? { color: 'red' } : undefined} onClick={onImageUpload} {...dragProps} className="form-control" >
                                      Cliquer pour ajouter une photo
                                    </button>
                                    }
                                      
                                      {imageList.length > 0 &&
                                      imageList.map((image, index) => (
                                        <div className="col-md-2" >
                                        <div key={index} className="image-item">
                                          <img src={image['data_url']} alt="" width="1000" />
                                          <div className="image-item__btn-wrapper">
                                            <button type="button" className="btn btn-danger btn-sm" onClick={() => onImageRemove(index)}>Supprimer</button>
                                          </div>
                                        </div>
                                        </div>
                                      ))
                                      }
                                    
                                  </>)}
                                </ImageUploading><br />
              <center><button className="btn btn-success">Mettre à jour l'affaire du mois</button></center>
            </Card.Text>
          </Card.Body>
        </Card>
        <br />
        
      </div>
        </Form>
        <Form onSubmit={postPDF}>
        <Card>
          <CardHeader>Catalogue</CardHeader>
          <Card.Body>
            <Card.Text>
              <input type='checkbox' name='afficher' id="afficher" />
            <input type="file" name="file" id="resume"  />
            <button className="btn btn-success" >Ajouter le Catalogue</button>
            <br /><br />
             {
                (pdfOn) ? "VISIBLE SUR LE SITE" : <strong >PDF hors ligne</strong>
             }
            <br />

            {
            pdf !== '' && <object type="application/pdf" data={pdf} width="100%" height="1000px">
              <embed type="application/pdf" src={pdf} />
            </object>}
            
            </Card.Text>
          </Card.Body>
        </Card>
        </Form>
        
      </Tab>
    </Tabs>
              
      </div>
      </Container>
    </>
  );

  function deleteDetailInList(e) {
    let newLisdt = detailsFieldsList.filter((x) => x.index != (e.target.attributes.idDetailDelete.value))

    setDetailsFieldsList(newLisdt)

  }

  function addFieldDetails(e) {

    if(refDetailName.current.value === "" || refDetailValue.current.value === "") {
      toast.error('Auncun des deux champs ne peut être vide')
      return
    }
    
    setDetailsFieldsList([...detailsFieldsList, {
      detail: refDetailName.current.value,
      value: refDetailValue.current.value,
      index: (detailsFieldsList.length + 1)
    }])

    toast.success(`Le détail ${refDetailName.current.value} à bien été ajouté avec la valeur ${refDetailValue.current.value}`)

    refDetailName.current.value = ""
    refDetailValue.current.value = ""
    
  }

}



export default App;
