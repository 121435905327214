import { createContext, useState } from "react";

export const ConnectedContext = createContext({connected: false, toggleConnected: () => {}})

export function ConnectedContextProvider({children}) {
    const [connected, setConnected] = useState(false);

    const toggleConnected = () => {
        setConnected(!connected); 
    }

    return <ConnectedContext.Provider value={{connected, toggleConnected}} >{children}</ConnectedContext.Provider>

}